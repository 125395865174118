import React, { useContext, useState } from 'react'
import MauschlerSelector from '../Components/MauschlerSelector'
import SpendTypePicker from '../Components/SpendTypePicker'
import axios from 'axios'
import { formatDateEu, formatDateUs, makeNrEu, makeNrUs } from '../utils/helperFunc'
import { LanguageContext } from '../utils/ProvideLang'

export default function SpendAnalysisPerType() {
    const { userLanguage, dictionary } = useContext(LanguageContext)
    const [zahler, setZahler] = useState("Bärchen")
    const [commodity, setCommodity] = useState("")
    const [resLoader, setResLoader] = useState("")
    const [spendData, setSpendData] = useState([])

    const changeCommodity = (event, value) => {
        setCommodity(value?.title.trim() || "")
    }

    const handleSearch = (e) => {
        e.preventDefault()
        var inputObj = {
            zahler: zahler,
            commodity: commodity
        }
        console.log(inputObj)
        setResLoader(dictionary.loadingData)
        setSpendData([])
        axios.post('/spend-data-by-commodity', inputObj).then((res) => {
            if (res.data.length === 0) {
                setResLoader(dictionary.nothingFound)
            } else {
                setResLoader("")
                setSpendData(res.data)
            }
        }).catch((err) => {
            console.log(err)
            setResLoader(dictionary.somethingWentWrong)
        })
    }

    return (
        <div className="flex justicy-center flex-row flex-wrap content-evenly flex-col mt-4">
            <div className="flex flex-col w-80">
                <form onSubmit={handleSearch} method="POST">

                    <MauschlerSelector title={dictionary.whoIsThisAbout} value={zahler} onChange={setZahler} />
                    <SpendTypePicker required={true} onChange={changeCommodity} />
                    <div class="mb-4 w-full flex flex-col">
                        <button type="submit" class="application-button">{dictionary.submit}</button>
                    </div>
                </form>
            </div>
            <div class="flex flex-col content-center w-auto max-w-sm ">
                <h5 class="text-center">{resLoader}</h5>
                <table class="table-auto divide-y divide-gray-700">
                    <thead class="bg-gray-50">
                        {spendData.length === 0 ? "" :
                            <tr>
                                <th scope="col" class="mausch-table-header">{dictionary.date}</th>
                                <th scope="col" class="mausch-table-header">{dictionary.amount}</th>
                            </tr>}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {spendData.map((elem) => {
                            return (<tr>
                                <td className="px-2 py-4 whitespace-wrap text-sm">{userLanguage === "en" ? formatDateUs(new Date(elem.dat)) : formatDateEu(new Date(elem.dat))}</td>
                                <td className="px-4 py-4 whitespace-wrap text-sm">{userLanguage === "en" ? makeNrUs(elem.amt) : makeNrEu(elem.amt)}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
