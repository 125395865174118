import React, { useContext } from 'react'
import MenuItem from '../Components/MenuItem'
import { LanguageContext } from '../utils/ProvideLang'

export default function Dashboard() {
    const { dictionary } = useContext(LanguageContext)
    return (
        <div class="w-full p-3 flex justify-center flex-col">
            <MenuItem link="newSpend" txt={dictionary.newSpendButton} />
            <MenuItem link="allSpends/Mäuschen" txt={dictionary.maeuschensAusgabenButton} />
            <MenuItem link="allSpends/Bärchen" txt={dictionary.baerchensAusgabenButton} />
            <MenuItem link="analysis" txt={dictionary.spendAnalysis} />
            <MenuItem link="analysisPerType" txt={dictionary.spendAnalysisPerType} />
        </div>
    )
}
