import React, { useState, useContext } from 'react'
import axios from 'axios';
import { TextField } from '@material-ui/core';
import MauschlerSelector from '../Components/MauschlerSelector';
import { pad, makeNrEu, makeNrUs } from '../utils/helperFunc';
import { LanguageContext } from '../utils/ProvideLang';

export default function SpendAnalysis() {

    const { userLanguage, dictionary } = useContext(LanguageContext)
    const currDate = new Date()
    var totalAmount = 0
    const fromDateString = currDate.getUTCFullYear() + "-" + pad(currDate.getUTCMonth() + 1) + "-01"
    const toDateString = currDate.getUTCFullYear() + "-" + pad(currDate.getUTCMonth() + 1) + "-" + pad(currDate.getUTCDate())

    const [zahler, setZahler] = useState("Bärchen")
    const [fromDate, setFromDate] = useState(fromDateString)
    const [toDate, setToDate] = useState(toDateString)
    const [spendData, setSpendData] = useState([])
    const [resLoader, setResLoader] = useState("")

    function changeZahler(newZahler) {
        setZahler(newZahler)
        setSpendData([])
    }

    function getSpendData() {
        var inputObj = {
            zahler: zahler,
            fromDate: fromDate,
            toDate: toDate
        }
        setSpendData([])
        setResLoader(dictionary.loadingData)
        axios.post("/analyseSpend", inputObj).then((res) => {
            console.log(res.data)
            if (!res.data.length) {
                setResLoader(dictionary.nothingFound)
            } else {
                setResLoader("")
                setSpendData(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (

        <div className="flex justicy-center flex-row flex-wrap content-evenly flex-col mt-4">
            <div className="flex flex-col w-80">
                <MauschlerSelector title={dictionary.whoIsThisAbout} value={zahler} onChange={changeZahler} />

                <div className="flex flex-row space-x-2 mb-1">
                    <div class="mb-4 w-1/2 flex flex-col">
                        <label class="text-black-700 text-sm font-bold mb-2">{dictionary.startingFrom}</label>
                        <TextField onChange={(e) => { setFromDate(e.target.value) }} id="spenddate" type="date" defaultValue={fromDate} />
                    </div>
                    <div class="mb-4 w-1/2 flex flex-col ml-2">
                        <label class="text-black-700 text-sm font-bold mb-2">{dictionary.until}</label>
                        <TextField onChange={(e) => { setToDate(e.target.value) }} id="spenddate" type="date" defaultValue={toDate} />
                    </div>
                </div>
                <div class="mb-4 w-full flex flex-col">
                    <button className="application-button-full" onClick={(e) => { getSpendData() }} id="spenddate" type="date" defaultValue={toDate}>{dictionary.search}</button>
                </div>
            </div>
            <div class="flex flex-col content-center w-auto max-w-sm ">
                <h5 class="text-center">{resLoader}</h5>
                <table class="table-auto divide-y divide-gray-700">
                    <thead class="bg-gray-50">
                        {spendData.length === 0 ? "" :
                            <tr>
                                <th scope="col" class="mausch-table-header">{dictionary.description}</th>
                                <th scope="col" class="mausch-table-header">{dictionary.amount}</th>
                            </tr>}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {spendData.map((elem) => {
                            totalAmount += elem.amt
                            return (<tr>
                                <td className="px-2 py-4 whitespace-wrap text-sm">{elem.comm}</td>
                                <td className="px-4 py-4 whitespace-wrap text-sm">{userLanguage === "en" ? makeNrUs(elem.amt) : makeNrEu(elem.amt)}</td>
                            </tr>)
                        })}
                    </tbody>
                    {spendData.length === 0 ? "" :
                    <tfoot>

                        <td className="px-2 py-2 font-bold whitespace-wrap">{dictionary.totalAmount}</td>   
                        <td className="px-2 py-2 font-bold whitespace-wrap">{userLanguage === "en" ? makeNrUs(totalAmount) : makeNrEu(totalAmount)}</td>   
                    </tfoot>}
                </table>
            </div>
        </div>
    )
}
