import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../utils/useAuth';

function PublicRoute({restricted, children, ...rest}) {
    let auth = useAuth();
    console.log(`restricted is ${restricted}`)
    console.log(`auth.user is ${JSON.stringify(auth.user)}`)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user && restricted ? (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { from: location }
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
};

export default PublicRoute;