import React, { createContext, useEffect, useState} from 'react'
import axios from 'axios';

export const authContext = createContext();

export default function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

const authObj = {
    isAuthenticated: false,
    signout(cb) {
        authObj.isAuthenticated = false;
        setTimeout(cb, 100);
    }
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(localStorage.getItem("mauschLoggedIn")) setUser("userOne")
    }, [])
    
    const signin = async (passwordVal) => {
        const res = await axios.post("/login", {password: passwordVal})
        if(res.data) {
            authObj.isAuthenticated = true
            localStorage.setItem("mauschLoggedIn", true)
            setUser("userOne")
        }
        return res.data
    };

    const logOut = async() => {
        authObj.isAuthenticated = false
        localStorage.removeItem("mauschLoggedIn")
        setUser(null)
    }
    
    return {
        user,
        signin,
        logOut
    };
}

