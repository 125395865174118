import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Modal, Button, Box } from '@material-ui/core';
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { formatDateEu, formatDateUs, makeNrEu, makeNrUs } from '../utils/helperFunc';
import { LanguageContext } from '../utils/ProvideLang';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function AllSpends() {
    // hook for spend data
    const [spendData, setSpendData] = useState([])
    // get spender (Bärchen / Mäuschen) from URL param
    let { spender } = useParams()
    const { userLanguage, dictionary } = useContext(LanguageContext)
    // hook for modal
    const [open, setOpen] = useState(false)
    const [spndId, setSpndId] = useState(-1)
    const [tableIndex, setTableIndex] = useState(-1)

    // function to delete records
    // delete from backend and remove in frontend
    // id is id in backend, index is index value in frontend
    function openDeleteModal(id, index) {
        // first, open a confirmation pop up
        setSpndId(id)
        setTableIndex(index)
        setOpen(true)
    }

    function deleteItem() {
        console.log(`Deleting ${spndId}`)
        // call backend
        setOpen(false)
        let newSpendData = [...spendData]
        newSpendData.splice(tableIndex, 1)
        setSpendData(newSpendData)
        axios.get(`/deleteRecord/?id=${spndId}`).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }

    // initial data load of spend data
    useEffect(() => {
        axios.get(`/spendData/?owner=${spender}`).then((res) => {
            setSpendData(res.data)
        }).catch((err) => {
            console.log(`err is ${err}`)
            setSpendData([])
        })
    }, [spender])

    // if no data is given, display nothing
    if (!spendData.length) {
        return (
            <div class="flex justify-center">
                <div class="flex justify-center w-80">
                    {dictionary.loadingData}
                </div>
            </div>
        )
    }
    // render table with data
    return (
        <div class="flex justify-center">
            <Modal open={open} aria-labelledby="modal-title">
                <Box sx={{ ...style, width: 350 }}>
                    <h1 class="text-center mb-4" id="modal-title">{dictionary.deleteItemHeader}</h1>
                    <div class="grid grid-cols-2 ">
                        <div>
                            <Button variant="contained" onClick={() => setOpen(false)}>{dictionary.abort}</Button>
                        </div>
                        <div>
                            <Button variant="contained" color="secondary" onClick={() => deleteItem()}>{dictionary.delete}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <div class="">
                <table class="table-auto divide-y divide-gray-700">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{dictionary.date}</th>
                            <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{dictionary.description}</th>
                            <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{dictionary.amount}</th>
                            <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {spendData.map((elem, index) => {
                            return (<tr key={elem.id} >
                                <td className="px-4 py-4 whitespace-nowrap text-sm">{userLanguage === "en" ? formatDateUs(new Date(elem.dat)) : formatDateEu(new Date(elem.dat))}</td>
                                <td className="px-2 py-4 whitespace-nowrap text-sm">{elem.comm}</td>
                                <td className="px-4 py-4 whitespace-nowrap text-sm">{userLanguage === "en" ? makeNrUs(elem.amt) : makeNrEu(elem.amt)}</td>
                                <td className="px-1 py-4 whitespace-nowrap text-sm">
                                    <IconButton onClick={() => openDeleteModal(elem.id, index)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
