import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
    return (
        <div>
            This is about us
            Get back <Link to="/">HOME</Link>
        </div>
    )
}
