import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuItem(props) {
    return (
        <div class="flex justify-center m-1">
            <Link to={props.link}>
                <button class="application-button">{props.txt}</button>
            </Link>
        </div>
    )
}
