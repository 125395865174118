import React from 'react'

export default function MauschlerSelectorBoth(props) {
    return (
        <div className="mb-4 flex flex-col">
            <label className="block text-black-700 text-sm font-bold mb-2">
                {props.title}
            </label>
            <select value={props.zahler} onChange={(e) => { props.onChange(e.target.value) }} className="shadow appearance-ne border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-ne focus:shadow-outline">
                <option value="Mäuschen und Bärchen">Mäuschen und Bärchen</option>
                <option value="Bärchen">Bärchen</option>
                <option value="Mäuschen">Mäuschen</option>
            </select>
        </div>
    )
}
