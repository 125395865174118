import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAuth from '../utils/useAuth';
import { LanguageContext } from '../utils/ProvideLang';

export default function Login() {
    const { dictionary } = useContext(LanguageContext)
    let location = useLocation();
    let auth = useAuth();
    let history = useHistory()
    let { from } = location.state || { from: { pathname: "/dashboard" } };

    const [errMessage, setErrMessage] = useState("")
    const [passwordVal, setPasswordVal] = useState("")
    const handleLogin = async (e) => {
        e.preventDefault()
        if (passwordVal === "") {
            setErrMessage(dictionary.noPasswordError)
            return
        }
        const res = await auth.signin(passwordVal)
        if (res === true) {
            history.replace(from);
        } else {
            setErrMessage(dictionary.wrongPasswordError)
        }
    }

    return (
        <form onSubmit={handleLogin} method="POST">

            <div class="w-full p-3">
                <div class="flex justify-center">

                    <h2 class="font-bold text-md text-center">{dictionary.loginHeader}</h2>
                </div>
                <div class="m-3 flex justify-center">
                    <p>
                        {errMessage}
                    </p>
                </div>
                <div class="m-3 flex justify-center">
                    <input placeholder={dictionary.loginPasswordPlaceholder} class="shadow block object-center appearance-none border rounded w-full max-w-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type="password" value={passwordVal} onChange={e => { setPasswordVal(e.target.value); setErrMessage(""); }}></input>
                </div>
                <div class="m-3 flex justify-center">
                    <button type="submit" class="bg-transparent hover:bg-red-400 hover:text-white w-full max-w-md text-red-400 font-bold border border-red-400 py-2 px-3 hover:border-transparent rounded">{dictionary.loginButton}</button>
                </div>
            </div>
        </form>
    )
}
