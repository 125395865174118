exports.formatDateIso = (dateObj) => {
    try {
        return dateObj.getFullYear() + '-' + pad(dateObj.getMonth() + 1) + '-' + pad(dateObj.getDate())
    } catch(err) {
        console.log(err)
        return ""
    }
}

exports.formatDateEu = (dateObj) => {
    try {
        return  pad(dateObj.getDate()) + '.' + pad(dateObj.getMonth() + 1) + '.' + dateObj.getFullYear()
    } catch(err) {
        console.log(err)
        return ""
    }
}

exports.formatDateUs = (dateObj) => {
    try {
        return pad(dateObj.getMonth() + 1) + '/' + pad(dateObj.getDate()) + '/' + dateObj.getFullYear()
    } catch(err) {
        console.log(err)
        return ""
    }
}

exports.makeNrEu = (numberString) => {
    numberString = numberString.toFixed(2).replace('.', ',')
    // check if there even is a ",", if not, add ",00" and return
    if(numberString.indexOf(',') === -1) return numberString + ",00"
    if(numberString.split(',')[1].length === 1) return numberString + "0" 
    return numberString + ""
}

exports.makeNrUs = (numberString) => {
    numberString = numberString.toString()
    // check if there even is a ",", if not, add ",00" and return
    if(numberString.indexOf('.') === -1) return numberString + ".00"
    if(numberString.split('.')[1].length === 1) return numberString + "0" 
    return parseFloat(numberString).toFixed(2) + ""
}

function pad(number) { return number < 10 ? '0' + number : number }

exports.pad = (number) => { return number < 10 ? '0' + number : number }