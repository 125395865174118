import { React, useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router'
import useAuth from '../utils/useAuth';
import axios from 'axios';

function PrivateRoute({ children, ...rest }) {
    const auth = useAuth()
    const [authenticated, setAuthentication] = useState(true)
    useEffect(() => {
        axios.get('/checkLoginStatus').then((res) => {
            // user is logged in, all fine
        }).catch((err) => {
            console.log("err")
            auth.logOut()
            setAuthentication(false)
        })
    })
    if(!authenticated) {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                }
            />
        )
    }
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;