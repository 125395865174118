import { React, useState, useEffect, useContext } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import axios from 'axios'
import { LanguageContext } from '../utils/ProvideLang'

export default function SpendTypePicker(props) {
    
    const { dictionary } = useContext(LanguageContext)
    const [spendTypes, setSpendTypes] = useState([""])

    // get spend types from backend (all that have been used so far)
    useEffect(() => {
        axios.get('/allSpendTypes').then((res) => {
            let spends = []
            res.data.map((elem) => {
                if(elem.comm == null) return ""
                return spends.push({"title": elem.comm})
            })
            setSpendTypes(spends)
        }).catch((err) => {
            setSpendTypes([""])
        })
    }, [])

    return (
        <div class="mb-4 w-full flex flex-col ">
            <label class="box text-black-700 text-sm font-bold mb-2">{dictionary.kindOfExpense}</label>
            <Autocomplete freeSolo={true} id="spendtype" onChange={props.onChange} options={spendTypes} getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} required={props.required} className="bg-white" label={dictionary.placeholderCommodity} variant="outlined" />} />
        </div>
    )
}
