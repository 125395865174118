import React, { useState, useContext } from 'react'
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDateIso } from '../utils/helperFunc';
import SpendTypePicker from '../Components/SpendTypePicker';
import MauschlerSelectorBoth from '../Components/MauschlerSelectorBoth';
import { LanguageContext } from '../utils/ProvideLang';

export default function NewSpend() {
    const { dictionary } = useContext(LanguageContext)

    const currDate = new Date()
    const currDateString = formatDateIso(currDate) // userLanguage === "en" ? formatDateUs(currDate) : formarDateEu(currDate)
    
    const [owner, setZahler] = useState("Mäuschen und Bärchen")
    const [amt, setAmount] = useState(0)
    const [currency, setCurrency] = useState("SEK")
    const [comm, setSpendType] = useState("")
    const [dat, setSpendDate] = useState(currDateString)

    let location = useLocation()
    let history = useHistory()
    let { from } = location.state || { from: { pathname: "/dashboard" } }
    
    
    const onSpendTypeChange = (event, value) => {
        setSpendType(value.title.trim())
    }
    
    const handleNewSpend = (e) => {
        e.preventDefault()
        var inputObj = {
            owner: owner,
            amt: amt,
            currency: currency,
            comm: comm,
            dat: dat
        }
        console.log(inputObj)
        axios.post('/newSpend', inputObj).then((res) => {
            history.replace(from)
        }).catch((err) => {
            console.log(err)
        })
    }
    
    return (
        <div class="flex justify-center mt-4">
            <div class="w-80">
                <form onSubmit={handleNewSpend} method="POST">
                    <MauschlerSelectorBoth title={dictionary.whoPaid} value={owner} onChange={setZahler} />
                    <div class="mb-4 space-x-5 w-full flex flex-row">
                        <div>
                            <label class="box text-black-700 text-sm font-bold mb-2">{dictionary.howMuchDidItCost}</label>
                            <input required type="number" step=".01" onChange={(e) => { setAmount(e.target.value) }} placeholder={dictionary.priceExample} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></input>
                        </div>
                        <div>
                            <label class="box text-black-700 text-sm font-bold mb-2">{dictionary.currency}</label>
                            <select value={currency} onChange={(e) => { setCurrency(e.target.value) }} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                <option value="SEK">SEK</option>
                                <option value="EUR">EUR</option>
                            </select>
                        </div>
                    </div>
                    <SpendTypePicker required={true} onChange={onSpendTypeChange} />
                    <div class="mb-4 w-full flex flex-col">
                        <label class="box text-black-700 text-sm font-bold mb-2">{dictionary.dateOfExpense}</label>
                        <TextField onChange={(e) => { setSpendDate(e.target.value) }} id="spenddate" type="date" defaultValue={currDateString} />
                    </div>
                    <div class="w-full">
                        <button type="submit" class="application-button">{dictionary.submit}</button>
                    </div>
                </form>
            </div>
        </div>

    )
}
