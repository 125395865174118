import './App.css';
import { BrowserRouter as Router, Switch, Link } from 'react-router-dom'
import About from './Pages/About';
import Dashboard from './Pages/Dashboard';
import PrivateRoute from './Components/PrivateRoute';
import PublicRoute from './Components/PublicRoute';
import Login from './Pages/Login';
import { ProvideLang } from './utils/ProvideLang';
import LanguageSelector from './Components/LanguageSelector';
import ProvideAuth from './utils/ProvideAuth';
import NewSpend from './Pages/NewSpend';
import AllSpends from './Pages/AllSpends';
import SpendAnalysis from './Pages/SpendAnalysis'
import SpendAnalysisPerType from './Pages/SpendAnalysisPerType';

function App() {
  return (
    <div class="">
      <ProvideAuth>
        <ProvideLang>
          <Router>
            <div class="bg-red-400 w-auto">
              <div class="mx-auto py-3 text-center grid grid-cols-12 gp-4">
                <div className="col-span-3">

                </div>
                <div class="text-white font-bold text-2xl flex-grow col-span-6">
                  <span class="font-comic">
                    <Link to="/dashboard">Mauschel-App</Link>
                  </span>
                </div>
                <div className="font-comic pt-1 flex-grow col-span-3">
                  <LanguageSelector />
                </div>
              </div>
            </div>
            <div class="bg-red-100 h-full p-4">

              <Switch>

                <PrivateRoute exact path="/"><Dashboard /></PrivateRoute>
                <PublicRoute restricted={true} exact path="/login"><Login /></PublicRoute>
                <PrivateRoute exact path="/dashboard"><Dashboard /></PrivateRoute>
                <PrivateRoute path="/allSpends/:spender"><AllSpends /></PrivateRoute>
                <PrivateRoute exact path="/newSpend"><NewSpend /></PrivateRoute>
                <PrivateRoute exact path="/analysis"><SpendAnalysis /></PrivateRoute>
                <PrivateRoute exact path="/analysisPerType"><SpendAnalysisPerType /></PrivateRoute>
                <PublicRoute exact path="/about-us" component={About} />
              </Switch>
            </div>
          </Router>
        </ProvideLang>
      </ProvideAuth>
    </div>
  );
}

export default App;
